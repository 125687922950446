import React, { useState } from 'react';
import PostCommentStyles from './post-comment.module.css';
import { FirebaseComment } from '../../../models/firebase.model';

const PostComment = () => {
    const [formData, setFormData] = useState<FirebaseComment>({
        name: '',
        address: '',
        email: '',
        phone: '',
        message: ''
    });
    const [isPosted, setIsPosted] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value, type } = event.target;
    
        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: (event.target as HTMLInputElement).checked,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // You can access the form data in the formData object
        await fetch(
            'https://levera-demo-default-rtdb.firebaseio.com/livera-comments.json',
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify(formData)
            }
        )
        setIsPosted(true)

        setTimeout(() => {
            setIsPosted(false)
            setFormData({
                name: '',
                address: '',
                email: '',
                phone: '',
                message: ''
            })
        }, 5000)
    };

    return (
        <section className="ttm-row conatact-section bg-layer-equal-height mt_15 clearfix">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div style={{position: 'relative'}} className="col-bg-img-eight ttm-bg ttm-col-bgimage-yes ttm-textcolor-white spacing-11 z-index-2">
                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                            <div style={{position: 'absolute', inset: 0, background: '#000', opacity: 0.25}}></div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="ttm-bg ttm-col-bgcolor-yes ttm-right-span spacing-12">
                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                            <div className="layer-content">
                                <div className="section-title">
                                    <div className="title-header">
                                        <h2 className="title">Send Your Message To Us</h2>
                                    </div>
                                </div>
                                <div className="padding_top30">
                                    <form id="contact_form" className="contact_form wrap-form clearfix" onSubmit={handleSubmit}>
                                        <div className="row ttm-boxes-spacing-20px">
                                            <div className="col-md-6">
                                                <label>
                                                    <span className="text-input">
                                                        <input
                                                            name="name"
                                                            type="text"
                                                            value={formData.name}
                                                            onChange={handleChange}
                                                            placeholder="First Name..."
                                                            required
                                                        />
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-md-6">
                                                <label>
                                                    <span className="text-input">
                                                        <input
                                                            name="address"
                                                            type="text"
                                                            value={formData.address}
                                                            onChange={handleChange}
                                                            placeholder="Last Name..."
                                                            required
                                                        />
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-md-6">
                                                <label>
                                                    <span className="text-input">
                                                        <input 
                                                            name="email" 
                                                            type="email" 
                                                            value={formData.email} 
                                                            onChange={handleChange} 
                                                            placeholder="Email Address..."
                                                            required
                                                        />
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-md-6">
                                                <label>
                                                    <span className="text-input">
                                                        <input 
                                                            name="phone" 
                                                            type="text" 
                                                            value={formData.phone} 
                                                            onChange={handleChange} 
                                                            placeholder="Phone Number..."
                                                            required
                                                        />
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-md-12">
                                                <label>
                                                    <span className="text-input">
                                                        <textarea
                                                            name="message"
                                                            cols={50}
                                                            rows={5}
                                                            value={formData.message}
                                                            onChange={handleChange}
                                                            placeholder="Enter Message Here..."
                                                            required
                                                        ></textarea>
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-lg-12">
                                                {/* <p className="cookies">
                                                    <input id="cookies-consent" name="cookiesConsent" type="checkbox" checked={formData.cookiesConsent} onChange={handleChange} />
                                                    <label htmlFor="cookies-consent"> Save my name, email in this browser for the next time Send message</label>
                                                </p> */}
                                                {
                                                    !isPosted ?
                                                        <button
                                                            className={`${PostCommentStyles.post_button} mt-15`}
                                                            style={{ fontSize: 14 }} type='submit'
                                                        >
                                                            Post Comment
                                                        </button>
                                                        : <p style={{ fontSize: 18 }}>
                                                            🎉 Thank you for reaching out! Your message has been successfully received. We will get back to you shortly. 📬😊
                                                        </p>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PostComment