import { PROJECTS } from "../../../constants/projects.constant";
import ProjectCard from "../../Project/Project-Card/Project-Card";

const Main = () => {
    return (
        <div className="site-main">
            <section className="ttm-row grid-section clearfix">
                <div className="container">
                    <div className="row">
                        {
                            PROJECTS.map(({slug, title, listViewDescription, listViewImageUrl}) => (
                                <ProjectCard
                                    key={slug}
                                    slug={slug}
                                    title={title}
                                    description={listViewDescription}
                                    imageUrl={listViewImageUrl}
                                />
                            ))
                        }
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Main