import { Link } from "react-router-dom";

const Header = () => {
    return (
        <div className="ttm-page-title-row">
            <div className="ttm-page-title-row-inner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="page-title-heading">
                                <h2 className="title">Projects</h2>
                            </div>
                            <div className="breadcrumb-wrapper">
                                <div className="container">
                                    <div className="breadcrumb-wrapper-inner">
                                        <span>
                                            <Link title="Go to Home." to="/" className="home"><i className="themifyicon ti-home"></i>&nbsp;&nbsp;Home</Link>
                                        </span>
                                        <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                        <span>Projects</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header