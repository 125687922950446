import { SOCIAL_MEDIA_LINKS } from "../../constants/social.constants";

const SocialMediaLinks = () => {
    return (
        <ul className="list-inline">
            {
                SOCIAL_MEDIA_LINKS.map(link => (
                    <li key={link.icon}>
                        <a href={link.href} target="_blank" rel="noreferrer"><i className={`fa fa-${link.icon}`}></i></a>
                    </li>
                ))
            }
        </ul>
    );
}

export default SocialMediaLinks;