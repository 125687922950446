import Layout from '../../components/Layout/Layout';
import Header from '../../components/Projects/Header/Header';
import Main from '../../components/Projects/Main/Main';
import { META_DESCRIPTION, META_TITLE } from '../../constants/seo.constants';

const Projects = () => {
    return (
        <Layout title={META_TITLE.PROJECTS} description={META_DESCRIPTION.PROJECTS}>
            <Header />
            <Main />
        </Layout>
    );
}

export default Projects