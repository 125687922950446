import { BUSINESS_DETAILS } from '../../../constants/social.constants'
import PostComment from '../Post-Comment/Post-Comment'

const ContactUsMain = () => {
    return (
        <div className="site-main">
            <section className="ttm-row pt-85 res-991-pt-45 pb-0 res-991-pb-0 clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="featured-icon-box icon-align-top-content style7">
                                <div className="featured-inner">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md ttm-icon_element-style-square">
                                            <i className="flaticon flaticon-call-1"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Phone Number</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>{BUSINESS_DETAILS.phoneNumber}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="featured-icon-box icon-align-top-content style7">
                                <div className="featured-inner">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md ttm-icon_element-style-square">
                                            <i className="flaticon flaticon-email"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Email Address</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>{BUSINESS_DETAILS.mail}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="featured-icon-box icon-align-top-content style7">
                                <div className="featured-inner">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md ttm-icon_element-style-square">
                                            <i className="flaticon flaticon-navigation"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Visit Us On</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>{BUSINESS_DETAILS.address.siteName}</p>
                                            <p>{BUSINESS_DETAILS.address.sector}, {BUSINESS_DETAILS.address.placeName}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="featured-icon-box icon-align-top-content style7">
                                <div className="featured-inner">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md ttm-icon_element-style-square">
                                            <i className="flaticon flaticon-wall-clock"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Visit Between</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Mon - Sat : {BUSINESS_DETAILS.openingHours}</p>
                                            <p>Sunday : Closed</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <PostComment />

            {/* <div id="google_map" className="google_map">
                <div className="map_container">
                    <div id="map">
                        <iframe title="location" src={BUSINESS_DETAILS.address.url} height="400" loading="lazy"></iframe>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default ContactUsMain