import { Link } from 'react-router-dom';

const CustomError = () => {
    return (
        <section className="error-404">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="page-content"><h3>Opps</h3></div>
                        <header className="page-header"><h2 className="page-title">404</h2></header>
                        <div className="page-content"><p>This page may have been moved or deleted.</p></div>
                        <Link className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor" to="/">Back To Home</Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CustomError