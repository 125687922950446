export const META_TITLE = {
    HOME: 'Livera | Interior Designer in Hyderabad',
    CONTACT_US: 'Contact Livera - Premier Interior Designers for Your Dream Home',
    ARCHITECTURE_SERVICE: 'Architecture Services by Livera - Building Dreams into Reality',
    INTERIOR_DESIGN_SERVICE: 'Interior Design by Livera - Create Inspiring Interiors',
    RETAIL_DESIGNS_SERVICE: 'Commercial Design Solutions by Livera - Elevate Your Brand',
    LAYOUTS_SERVICE: '2D/3D Layout Services by Livera - Visualize Your Space',
    INTER_DESIGN_SERVICE: "Inter Design Services by Livera - Creating Unique Interiors",
    DECORATION_ART_SERVICE: 'Decoration Art Services by Livera - Elegance in Every Detail',
    PROJECTS: "Livera's Design and Architecture Portfolio",
    HOME_THEATER: "Luxury Home Theater Design Services | Livera Interior Design",
    HOME_AUTOMATION: "Livera | Elevating Homes with Intelligent Home Automation Solutions",
    FURNITURE_LAYOUTS: "Livera | Expert Furniture Layout Design for Stunning Interiors",
    ELECTRIC_LAYOUTS: "Livera | Cutting-Edge Electrical Layouts for Contemporary Interiors",
    PROJECT_DEVELOPMENT: "Elevate Your Living Spaces with Livera: Interior Design Excellence",
    404: "Oops! Page Not Found - Livera Interior Design"
}

export const META_DESCRIPTION = {
    HOME: 'Livera is Hyderabad’s leading interior design company, specializing in modern home decor and innovative living spaces. Discover our creative solutions for transforming your interiors into stylish and functional masterpieces.',
    CONTACT_US: "Contact Livera, Hyderabad's premier interior designers, for bespoke design and architecture services. Let's create your dream home or office with personalized solutions tailored to your taste and needs.",
    ARCHITECTURE_SERVICE: "Discover architecture services in Hyderabad by Livera. Our expert team designs innovative and functional spaces, turning your dream home or office into reality. Contact us to learn more.",
    INTERIOR_DESIGN_SERVICE: "Livera, the top interior designer in Hyderabad, provides premium design solutions to elevate your living or workspace. Transform your interiors with our unique designs and creative expertise.",
    RETAIL_DESIGNS_SERVICE: "Boost your brand with Livera's retail interior design services in Hyderabad. Our innovative designs create captivating retail spaces that enhance customer experience and brand presence.",
    LAYOUTS_SERVICE: "Explore Livera's 2D/3D layout services in Hyderabad. Perfectly visualize your interior design projects and bring your vision to life with detailed and precise layouts.",
    INTER_DESIGN_SERVICE: "Hyderabad's premier interior design services by Livera. We specialize in creating unique, stylish, and functional interiors for homes, offices, and commercial spaces.",
    DECORATION_ART_SERVICE: 'Livera brings elegance to your Hyderabad home with exquisite decoration art services. Elevate your spaces with our bespoke home decor and artistic designs.',
    PROJECTS: "Explore Livera's diverse portfolio of interior design and architecture projects in Hyderabad. From luxury homes to commercial spaces, get inspired by our creative designs.",
    HOME_THEATER: "Transform your Hyderabad home into a cinematic retreat with Livera's luxury home theater designs. Discover how we blend style and functionality for the ultimate entertainment experience.",
    HOME_AUTOMATION: "Upgrade your Hyderabad home with Livera's intelligent home automation services. Experience luxury living with seamless technology integration into your interiors.",
    FURNITURE_LAYOUTS: "Livera, Hyderabad's top interior design firm, offers expert furniture layout services. Optimize your space with stylish and functional furniture arrangements that reflect your taste.",
    ELECTRIC_LAYOUTS: "Livera provides advanced electrical layout solutions in Hyderabad. Incorporating lighting, power, and technology, our designs ensure modern and efficient interiors for your home or office.",
    PROJECT_DEVELOPMENT: "Livera specializes in project development for interior design in Hyderabad. From concept to completion, we create stunning, functional spaces that reflect your vision and lifestyle.",
    404: "We're sorry, the page you're looking for isn't available. Explore Livera’s interior design and architecture services in Hyderabad and create the space of your dreams. Contact us for assistance."
}
