import main from '../../assets/images/services/furniture/main.jpg';
import consult from '../../assets/images/services/furniture/consult.jpg';
import design from '../../assets/images/services/furniture/design.jpg';
import selection from '../../assets/images/services/furniture/selection.jpg';
import placement from '../../assets/images/services/furniture/placement.jpg';
import styling from '../../assets/images/services/furniture/styling.jpg';
import { META_DESCRIPTION, META_TITLE } from '../../constants/seo.constants';
import Layout2 from '../ServicesLayout/Layout2';

const info = {
  metaTitle: META_TITLE.FURNITURE_LAYOUTS,
  metaDescription: META_DESCRIPTION.FURNITURE_LAYOUTS,
  title: "Furniture Layout",
  mainHeading: "Revealing the Mastery of Furniture Layouts at Livera: Crafting Stylish and Functional Spaces",
  image1: main,
  mainParagraphs: ["At Livera, we believe that the arrangement of furniture is a subtle art form that can transform any space into a masterpiece of comfort and aesthetics. Our Furniture Layouts page is your gateway to discovering the magic we weave with furniture design and arrangement. With years of experience in interior design and development", "we've perfected the art of creating harmonious, functional, and visually striking living spaces. Step into a world where every chair, table, and sofa finds its perfect place, enhancing the flow, style, and ambiance of your home. Explore Livera's Furniture Layouts and let us redefine the way you view and experience your living spaces."],
  points: [
    {
      image: consult,
      title: "Initial Consultation",
      description: "Our journey towards crafting the perfect furniture layout begins with a thorough consultation. We listen intently to your preferences, needs, and vision for your space. Whether it's your living room, bedroom, or any other area, we tailor our layout solutions to align with your unique lifestyle and design aspirations."
    },
    {
      image: design,
      title: "Conceptualization and Design",
      description: "With your insights as our guide, our talented interior designers delve into the creative process. We meticulously sketch and design layouts that not only maximize functionality but also evoke the desired mood and style. Our designs are a fusion of your dreams and our expertise, resulting in spaces that tell your unique story"
    },
    {
      image: selection,
      title: "Selection of Furniture Pieces",
      description: "Livera boasts a curated selection of exquisite furniture pieces from. We carefully choose each item to harmonize with the design concept, ensuring that every chair, table, and decorative element contributes to the overall aesthetics and functionality of the space."
    },
    {
      image: placement,
      title: "Placement and Arrangement",
      description: "Our experienced team handles the intricate task of placing and arranging the selected furniture pieces. Every element is positioned with precision, considering factors such as traffic flow, focal points, and spatial balance. The result is a layout that not only looks stunning but also enhances the comfort and usability of your space."
    },
    {
      image: styling,
      title: "Final Touches and Styling",
      description: "We pay meticulous attention to detail, adding final touches and accents that bring the space to life. From the placement of accessories to the choice of color palettes, Livera's designers ensure that every element complements the furniture layout, creating a cohesive and inviting atmosphere."
    },
  ],
  name: "furniture",
}

const FurnitureLayout = () => {
  return (
    <Layout2 info={info} />
  )
}

export default FurnitureLayout