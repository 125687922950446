import { BUSINESS_DETAILS } from "../../../constants/social.constants";

const Contact = () => {
    return (
        <aside className="widget contact-widget with-title">
            <h3 className="widget-title">Contact</h3>
            <div className="featured-icon-box icon-align-before-content icon-ver_align-top style1">
                <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                        <i className="flaticon flaticon-call-1"></i>
                    </div>
                </div>
                <div className="featured-content">
                    <div className="featured-title">
                        <h3>Phone Number</h3>
                    </div>
                    <div className="featured-desc">
                        <p>{BUSINESS_DETAILS.phoneNumber}</p>
                    </div>
                </div>
            </div>
            <div className="featured-icon-box icon-align-before-content icon-ver_align-top style1">
                <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                        <i className="flaticon flaticon-email"></i>
                    </div>
                </div>
                <div className="featured-content">
                    <div className="featured-title">
                        <h3>Email Address</h3>
                    </div>
                    <div className="featured-desc">
                        <p>{BUSINESS_DETAILS.mail}</p>
                    </div>
                </div>
            </div>
            <div className="featured-icon-box icon-align-before-content icon-ver_align-top style1">
                <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                        <i className="flaticon flaticon-navigation"></i>
                    </div>
                </div>
                <div className="featured-content">
                    <div className="featured-title">
                        <h3>Location</h3>
                    </div>
                    <div className="featured-desc">
                        <p>{BUSINESS_DETAILS.address.location}</p>
                    </div>
                </div>
            </div>
        </aside>
    );
}

export default Contact