import aparna from '../assets/images/componyLogos/aparna.jpeg';
import honor from '../assets/images/componyLogos/honor.jpeg';
import lancohills from '../assets/images/componyLogos/lancohills.jpeg';
import lansum from '../assets/images/componyLogos/lansum.jpeg';
import midvalleycity from '../assets/images/componyLogos/midvalleycity.jpeg';
import myhomegroup from '../assets/images/componyLogos/myhomegroup.jpeg';
import poulomi from '../assets/images/componyLogos/poulomi.jpeg';
import raintreepark from '../assets/images/componyLogos/raintreepark.jpeg';
import urjith from '../assets/images/componyLogos/urjith.jpeg';

export const images = [
  aparna, honor, lancohills, lansum, midvalleycity, myhomegroup, poulomi, raintreepark, urjith
]