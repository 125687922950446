import { Helmet } from 'react-helmet';
import { META_DESCRIPTION, META_TITLE } from '../../constants/seo.constants';

interface MetaProps {
    url?: string;
    description?: string;
    title?: string;
    mediaUrl?: string;
    pathName?: string;
    keywords?: string;
}

const Meta = (props: MetaProps) => {
    const {
        title = META_TITLE.HOME,
        description = META_DESCRIPTION.HOME,
        mediaUrl = '/images/logo.svg',
        url,
        keywords
    } = props;

    const keywordString = [
        "interior designer in Hyderabad",
        "best interior designer Hyderabad",
        "home decor services Hyderabad",
        "luxury interiors Hyderabad",
        "modern interior design Hyderabad",
        "top architects Hyderabad",
        "2D and 3D layout design Hyderabad",
        "home automation services Hyderabad",
        "furniture layout design Hyderabad",
        "residential interior design Hyderabad",
        "commercial interior design Hyderabad",
        "office interior design Hyderabad",
        "retail space design Hyderabad",
        "smart home solutions Hyderabad",
        "architectural services Hyderabad",
        "decoration art Hyderabad",
        "custom furniture Hyderabad",
        "hyderabad interior decor ideas",
        "affordable interior design Hyderabad",
        "hyderabad luxury home design"
    ].join(", ")

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="date" content="2024-11-24" />
            <meta itemProp="datePublished" content="2019-10-31" />
            <meta name="keywords" content={keywordString} />

            <meta itemProp="name" content="Livera" />
            <meta itemProp="url" content={url ?? 'https://livera.design'} />
            <meta itemProp="description" content={description} />
            <meta itemProp="thumbnailUrl" content={mediaUrl} />
            <link rel="image_src" href={mediaUrl} />
            <meta itemProp="image" content={mediaUrl} />
            <link rel="canonical" href={url} />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url ?? 'https://livera.design'} />
            <meta property="og:site_name" content="Livera" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={mediaUrl} />
            <meta property="og:image:alt" content="Livera Image" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
        </Helmet>
    );
}

export default Meta