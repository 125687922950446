import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import Home from './pages/Home/Home';
import ContactUs from './pages/Contact-Us/Contact-Us';

import './fonts.css';
import Architecture from './components/Services/Architecture.component';
import Interior from './components/Services/Interior.component';
import { RetailDesign } from './components/Services/RetailDesign.component';
import Layouts from './components/Services/Layouts.component';
import InterDesign from './components/Services/InterDesign';
import DecorationArt from './components/Services/DecorationArt.component';
import Projects from './pages/Projects/Projects';
import ProjectDetails from './pages/Project-Details/Project-Details';
import Layout from './components/Layout/Layout';
import CustomError from './components/Error/Error';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { META_DESCRIPTION, META_TITLE } from './constants/seo.constants';
import ElectricLayout from './components/Services/ElectricLayout';
import FurnitureLayout from './components/Services/FurnitureLayout';
import ProjectDevelopment from './components/Services/ProjectDevelopment';
import HomeTheater from './components/Services/HomeTheater';
import HomeAutomation from './components/Services/HomeAutomation';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/'>
          <Route index element={<Home />} />
        </Route>
        <Route path='/contact-us'>
          <Route index element={<ContactUs />} />
        </Route>
        <Route path='/projects'>
          <Route index element={<Projects />} />
        </Route>
        <Route path='/project/:slug'>
          <Route index element={<ProjectDetails />} />
        </Route>
        <Route path='/architecture'>
          <Route index element={<Architecture />} />
        </Route>
        <Route path='/interior'>
          <Route index element={<Interior />} />
        </Route>
        <Route path='/commercial-design'>
          <Route index element={<RetailDesign />} />
        </Route>
        <Route path='/layouts'>
          <Route index element={<Layouts />} />
        </Route>
        <Route path='/inter-design'>
          <Route index element={<InterDesign />} />
        </Route>
        <Route path='/decoration-art'>
          <Route index element={<DecorationArt />} />
        </Route>
        <Route path='/electric-layout'>
          <Route index element={<ElectricLayout />} />
        </Route>
        <Route path='/furniture-layout'>
          <Route index element={<FurnitureLayout />} />
        </Route>
        <Route path='/home-theater'>
          <Route index element={<HomeTheater />} />
        </Route>
        <Route path='/project-development'>
          <Route index element={<ProjectDevelopment />} />
        </Route>
        <Route path='/home-automation'>
          <Route index element={<HomeAutomation />} />
        </Route>
        <Route path='*' element={<Layout title={META_TITLE[404]} description={META_DESCRIPTION[404]} children={<CustomError />} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
