import Footer from "../Footer/Footer"
import Meta from "../Meta/Meta";
import Navbar from "../Navbar/Navbar"

interface LayoutProps {
    children: React.ReactNode;
    isShowTopNavbar?: boolean;
    title?: string;
    description?: string;
    mediaUrl?: string,
}

const Layout = ({ children, title, description, mediaUrl, isShowTopNavbar = false }: LayoutProps) => {
    return (
        <>
            <Meta title={title} description={description} mediaUrl={mediaUrl} />
            <main className="page ttm-bgcolor-grey">
                <Navbar isShowTopNavbar={isShowTopNavbar} />
                {children}
                <Footer />
            </main>
        </>
    );
}

export default Layout