export const NAV_LINKS = [
    {
        label: 'HOME',
        path: '/'
    },
    {
        label: 'HOME',
        path: '/'
    },
    {
        label: 'HOME',
        path: '/'
    },
    {
        label: 'HOME',
        path: '/'
    },
    {
        label: 'HOME',
        path: '/'
    }
];

export const FOOTER_QUICK_LINKS = [
    {
        title: 'Home',
        to: '/'
    },
    {
        title: 'Contact Us',
        to: '/contact-us'
    },
    {
        title: 'Projects',
        to: '/projects'
    },
    {
        title: 'Services',
        to: '/architecture'
    },
];