import { Link } from 'react-router-dom';
import main from '../../assets/images/services/decoratinart/main.jpg';
import Layout from '../Layout/Layout';
import { META_DESCRIPTION, META_TITLE } from '../../constants/seo.constants';
import SideBar from '../ServicesLayout/SideBar';

const DecorationArt = () => {
  return (
    <Layout title={META_TITLE.DECORATION_ART_SERVICE} description={META_DESCRIPTION.DECORATION_ART_SERVICE}>
      <div className="ttm-page-title-row">
        <div className="ttm-page-title-row-inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="page-title-heading">
                  <h2 className="title">Decoration art</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <div className="container">
                    <div className="breadcrumb-wrapper-inner">
                      <span>
                        <Link title="Go to Home." to="/" className="home"><i className="themifyicon ti-home"></i>&nbsp;&nbsp;Home</Link>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                      <span>Decoration art</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-main">
        <div className="ttm-row sidebar ttm-sidebar-left pb-45 ttm-bgcolor-white clearfix">
          <div className="container">
            <div className="row">
              <SideBar name='decoration' />
              <div className="col-lg-8 content-area">
                <article className="ttm-service-single-content-area">
                  <div className="ttm-service-featured-wrapper ttm-featured-wrapper">
                    <div className="ttm_single_image-wrapper mb-40 res-991-mb-30">
                      <img width="1200" height="800" className="img-fluid" src={main} alt="project-1"
                        style={{
                          height: '450px',
                          width: '799px',
                          objectFit: 'cover'
                        }}
                      />
                    </div>
                  </div>
                  <div className="ttm-service-classic-content">
                    <h2>Our Approach to Decoration Art</h2>
                    <p>
                      Our approach to decoration art is rooted in the belief that design is not just about aesthetics; it's about creating a harmonious environment that enhances your quality of life. We take the time to understand your vision, preferences, and lifestyle, allowing us to craft bespoke decoration art solutions that resonate with your individuality.
                    </p>
                    <p>Let us bring your vision to life. <Link to="/contact-us">Contact us</Link> today for a consultation and let the art of decoration flourish in your space.</p>
                    <div className="pt-20">
                      <h2>Service Advantages</h2>
                      <p>Discover the world of decoration art with Livera and experience the transformative power of design. Whether you're looking to refresh your home, elevate your business, or create a memorable hospitality space, we're here to turn your dreams into reality.</p>
                    </div>
                    <div className="ttm-bgcolor-white pt-15 pb-25">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="featured-icon-box icon-align-top-content style2">
                            <div className="featured-icon">
                              <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                <i className="flaticon flaticon-blueprint"></i>
                              </div>
                            </div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h3>Residential</h3>
                              </div>
                              <div className="featured-desc">
                                <p>Whether it's your cozy home, luxurious villa, or trendy apartment, we specialize in transforming residential spaces into stunning works of art. Our team of experienced designers and artisans meticulously curate every element to create a seamless blend of comfort and elegance</p>
                              </div>
                              <div className="ttm-footer">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="featured-icon-box icon-align-top-content style2">
                            <div className="featured-icon">
                              <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                <i className="flaticon flaticon-destination"></i>
                              </div>
                            </div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h3>Commercial</h3>
                              </div>
                              <div className="featured-desc">
                                <p>From offices to retail spaces, Livera excels in crafting unique decoration art concepts that leave a lasting impression on clients and customers. We understand the importance of functional yet aesthetically pleasing designs that elevate your business environment.</p>
                              </div>
                              <div className="ttm-footer">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <h2>Our Latest Project</h2>
                    <p>Contrary to popular belief, Lorem Ipsum is not simply <u><a className="ttm-textcolor-skincolor" href="/">random text.</a></u> It has roots in a <strong><em>piece of classical</em></strong> Latin literature from 45 BC, making it over 2000 years old.</p>
                    <div className="row slick_slider" data-slick='{"slidesToShow": 2, "slidesToScroll": 1, "arrows":false, "autoplay":false, "dots":false, "infinite":true, "responsive":[{"breakpoint":992,"settings":{"slidesToShow": 2}},{"breakpoint":620,"settings":{"slidesToShow": 1}}]}'>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style3">
                          <div className="featured-thumbnail">
                            <img width="610" height="750" className="img-fluid" src="https://via.placeholder.com/610x750/808080?text=610x750+portfolio-01-610x750.jpg" alt="" />
                          </div>
                          <div className="featured-content-inner">
                            <div className="featured-content">
                              <div className="featured-title">
                                <h3><Link to="/" tabIndex={0}>Modern Kitchen</Link></h3>
                              </div>
                              <div className="featured-desc">
                                <p>The style of the interior was defined as modern in light colors. Main materials used in project are wallpaper etc.</p>
                              </div>
                            </div>
                            <div className="ttm-footer">
                              <a className="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-dark" href="/" tabIndex={0}>Read More<i className="ti ti-plus"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style3">
                          <div className="featured-thumbnail">
                            <img width="610" height="750" className="img-fluid" src="https://via.placeholder.com/610x750/808080?text=610x750+portfolio-02-610x750.jpg" alt="" />
                          </div>
                          <div className="featured-content-inner">
                            <div className="featured-content">
                              <div className="featured-title">
                                <h3><Link to="/" tabIndex={0}>Interior Work</Link></h3>
                              </div>
                              <div className="featured-desc">
                                <p>The entire interior is the beginning of the history of the house, such as colors, lighting, and materials.</p>
                              </div>
                            </div>
                            <div className="ttm-footer">
                              <a className="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-dark" href="/" tabIndex={0}>Read More<i className="ti ti-plus"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style3">
                          <div className="featured-thumbnail">
                            <img width="610" height="750" className="img-fluid" src="https://via.placeholder.com/610x750/808080?text=610x750+portfolio-01-610x750.jpg" alt="" />
                          </div>
                          <div className="featured-content-inner">
                            <div className="featured-content">
                              <div className="featured-title">
                                <h3><Link to="/" tabIndex={0}>Modern Kitchen</Link></h3>
                              </div>
                              <div className="featured-desc">
                                <p>The style of the interior was defined as modern in light colors. Main materials used in project are wallpaper etc.</p>
                              </div>
                            </div>
                            <div className="ttm-footer">
                              <a className="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-dark" href="/" tabIndex={0}>Read More<i className="ti ti-plus"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DecorationArt