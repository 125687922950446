import main from '../../assets/images/services/2d3dlayouts/main.jpg';
import working from '../../assets/images/services/2d3dlayouts/working.jpg';
import placement from '../../assets/images/services/2d3dlayouts/placement.jpg';
import { META_DESCRIPTION, META_TITLE } from '../../constants/seo.constants';
import Layout1 from '../ServicesLayout/Layout1';

const info = {
  metaTitle: META_TITLE.LAYOUTS_SERVICE,
  metaDescription: META_DESCRIPTION.LAYOUTS_SERVICE,
  title: "2D/3D Layouts",
  mainHeading: "Creative Solutions By Inoterior Professional Designers",
  image1: main,
  mainParagraphs: ["At Livera, we understand that envisioning your dream interior is the first step toward making it a reality. That's why we offer cutting-edge 2D and 3D layout services that provide you with a clear and vivid preview of your future space. With 15 years of experience in interior design, we have refined our approach to transform your ideas into tangible, visual designs.", "At Livera, we blend innovation, technology, and artistic flair to create interior designs that surpass expectations. Our 2D and 3D layouts provide you with the clarity and confidence to embark on your interior design journey."],
  points: [
    {
      className: "pt-10",
      image: working,
      title: "Our Working Process",
      description: "At Livera, our working process is designed to seamlessly translate your vision into a fully realized interior",
      bulletPoints: [
        "Consultation and Conceptualization",
        "Measurement and Assessment",
        "Design and Visualization",
        "Material Selection and Budgeting",
        "Execution and Project Management"
      ]
    },
    {
      className: "pt-25",
      image: placement,
      title: "Our Commitment to You",
      description: "Utilizing cutting-edge technology to create precise and immersive visualizations",
      bulletPoints: [
        "Accessible Luxury",
        "Post-Project Support",
        "Sustainability and Environmentally-Friendly Practices",
        "Timely Delivery",
        "Attention to Detail"
      ]
    }
  ],
  name: "layouts"
}

const Layouts = () => {
  return (
    <Layout1 info={info} />
    // <Layout title={META_TITLE.LAYOUTS_SERVICE} description={META_DESCRIPTION.LAYOUTS_SERVICE}>
    //   <div className="ttm-page-title-row">
    //     <div className="ttm-page-title-row-inner">
    //       <div className="container">
    //         <div className="row align-items-center">
    //           <div className="col-lg-12">
    //             <div className="page-title-heading">
    //               <h2 className="title">2D/3D Layouts</h2>
    //             </div>
    //             <div className="breadcrumb-wrapper">
    //               <div className="container">
    //                 <div className="breadcrumb-wrapper-inner">
    //                   <span>
    //                     <Link title="Go to Home." to="/" className="home"><i className="themifyicon ti-home"></i>&nbsp;&nbsp;Home</Link>
    //                   </span>
    //                   <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
    //                   <span>2D/3D Layouts</span>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>


    //   <div className="site-main">

    //     <div className="ttm-row sidebar ttm-sidebar-left ttm-bgcolor-white clearfix">
    //       <div className="container">
    //         <div className="row">
    //           <div className="col-lg-4 widget-area sidebar-left">
    //             <aside className="widget widget-nav-menu">
    //               <h3 className="widget-title">More Services</h3>
    //               <ul className="widget-menu">
    //                 <li><Link to="/architecture">Architecture</Link></li>
    //                 <li><Link to="/interior">Interior Work</Link></li>
    //                 <li><Link to="/commercial-design">Retail Designs</Link></li>
    //                 <li className="active"><Link to="/layouts">2D/3D Layouts</Link></li>
    //                 <li><Link to="/inter-design">Inter Design</Link></li>
    //                 <li><Link to="/decoration-art">Decoration art</Link></li>
    //               </ul>
    //             </aside>
    //             <Contact />
    //             <aside className="widget widget-archive">
    //               <h3 className="widget-title">Gallery</h3>
    //               <div id="gallery-2" className="gallery-wrapper">
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch4} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch5} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch6} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch7} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch8} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch9} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch10} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch11} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch12} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //               </div>
    //             </aside>
    //           </div>
    //           <div className="col-lg-8 content-area">
    //             <article className="ttm-service-single-content-area">
    //               <h2>Creative Solutions By Inoterior Professional Designers</h2>
    //               <div className="ttm-service-featured-wrapper ttm-featured-wrapper">
    //                 <div className="ttm_single_image-wrapper pt-20 mb-20 res-991-mb-30">
    //                   <img width="799" height="350" className="img-fluid" src={arch2} alt="project-9" />
    //                 </div>
    //               </div>
    //               <div className="ttm-service-classic-content">
    //                 <p>At Livera, we understand that envisioning your dream interior is the first step toward making it a reality. That's why we offer cutting-edge 2D and 3D layout services that provide you with a clear and vivid preview of your future space. With 15 years of experience in interior design, we have refined our approach to transform your ideas into tangible, visual designs.</p>
    //                 <p>At Livera, we blend innovation, technology, and artistic flair to create interior designs that surpass expectations. Our 2D and 3D layouts provide you with the clarity and confidence to embark on your interior design journey.</p>
    //                 <div className="pt-10">
    //                   <div className="row">
    //                     <div className="col-md-6">
    //                       <div className="text-content">
    //                         <div className="icon-box"><i className="fa fa-check-square-o ttm-textcolor-skincolor"></i></div>
    //                         <div className="icon-content">
    //                           <h3>Our Working Process</h3>
    //                           <p>At Livera, our working process is designed to seamlessly translate your vision into a fully realized interior.</p>
    //                         </div>
    //                       </div>
    //                       <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor style2">
    //                         <li><i className="flaticon-work-done ttm-textcolor-skincolor"></i>
    //                           <span className="ttm-list-li-content">Consultation and Conceptualization</span>
    //                         </li>
    //                         <li><i className="flaticon-work-done ttm-textcolor-skincolor"></i>
    //                           <span className="ttm-list-li-content">Measurement and Assessment</span>
    //                         </li>
    //                         <li><i className="flaticon-work-done ttm-textcolor-skincolor"></i>
    //                           <span className="ttm-list-li-content">Design and Visualization</span>
    //                         </li>
    //                         <li><i className="flaticon-work-done ttm-textcolor-skincolor"></i>
    //                           <span className="ttm-list-li-content">Material Selection and Budgeting</span>
    //                         </li>
    //                         <li><i className="flaticon-work-done ttm-textcolor-skincolor"></i>
    //                           <span className="ttm-list-li-content">Execution and Project Management</span>
    //                         </li>
    //                       </ul>
    //                     </div>
    //                     <div className="col-md-6">
    //                       <div className="ttm_single_image-wrapper res-767-pt-30">
    //                         <img width="385" height="224" className="img-fluid" src={arch1} alt="single_image-10" />
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="pt-25">
    //                   <div className="row">
    //                     <div className="col-md-6">
    //                       <div className="ttm_single_image-wrapper res-767-pb-30">
    //                         <img width="385" height="224" className="img-fluid" src={arch3} alt="single_image-11" />
    //                       </div>
    //                     </div>
    //                     <div className="col-md-6">
    //                       <div className="text-content">
    //                         <div className="icon-box"><i className="fa fa-check-square-o ttm-textcolor-skincolor"></i></div>
    //                         <div className="icon-content">
    //                           <h3>Our Commitment to You</h3>
    //                           <p>Utilizing cutting-edge technology to create precise and immersive visualizations</p>
    //                         </div>
    //                       </div>
    //                       <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor style2">
    //                         <li><i className="flaticon-work-done ttm-textcolor-skincolor"></i>
    //                           <span className="ttm-list-li-content">Accessible Luxury</span>
    //                         </li>
    //                         <li><i className="flaticon-work-done ttm-textcolor-skincolor"></i>
    //                           <span className="ttm-list-li-content">Post-Project Support</span>
    //                         </li>
    //                         <li><i className="flaticon-work-done ttm-textcolor-skincolor"></i>
    //                           <span className="ttm-list-li-content">Sustainability and Environmentally-Friendly Practices</span>
    //                         </li>
    //                         <li><i className="flaticon-work-done ttm-textcolor-skincolor"></i>
    //                           <span className="ttm-list-li-content">Timely Delivery</span>
    //                         </li>
    //                         <li><i className="flaticon-work-done ttm-textcolor-skincolor"></i>
    //                           <span className="ttm-list-li-content">Attention to Detail</span>
    //                         </li>
    //                       </ul>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 {/* <div className="pt-30">
    //                   <h2>Words From Our Customers</h2>
    //                 </div>
    //                 <div className="row slick_slider slick-dots-style2" data-slick='{"slidesToShow": 2, "slidesToScroll": 1, "arrows":false, "autoplay":false, "dots":false, "infinite":true, "responsive":[{"breakpoint":1199,"settings": {"slidesToShow": 3}}, {"breakpoint":992,"settings":{"slidesToShow": 2}},{"breakpoint":620,"settings":{"slidesToShow": 1}}]}'>
    //                   <div className="col-lg-6">
    //                     <div className="testimonials style1">
    //                       <div className="testimonials-inner-item">
    //                         <div className="testimonial-content">
    //                           <div className="ttm-ratting-star">
    //                             <i className="fa fa-star"></i>
    //                             <i className="fa fa-star"></i>
    //                             <i className="fa fa-star"></i>
    //                             <i className="fa fa-star"></i>
    //                             <i className="fa fa-star-o"></i>
    //                           </div>
    //                           <blockquote>I love everything that put together for my living room! I just bought this house and only had a few items I wanted to keep. I’am able to help me visualize the room”</blockquote>
    //                           <div className="testimonial-avatar">
    //                             <div className="testimonial-img">
    //                               <img width="150" height="150" className="img-fluid" src="https://via.placeholder.com/150x150/808080?text=150x150+01.jpg" alt="testimonial-img" />
    //                             </div>
    //                             <div className="testimonial-caption">
    //                               <h3>Natalie Kameron</h3>
    //                               <label>Ceo /Manger</label>
    //                             </div>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="col-lg-6">
    //                     <div className="testimonials style1">
    //                       <div className="testimonials-inner-item">
    //                         <div className="testimonial-content">
    //                           <div className="ttm-ratting-star">
    //                             <i className="fa fa-star"></i>
    //                             <i className="fa fa-star"></i>
    //                             <i className="fa fa-star"></i>
    //                             <i className="fa fa-star"></i>
    //                             <i className="fa fa-star-o"></i>
    //                           </div>
    //                           <blockquote>“I hired inoterior for my living room, and did such an amazing job, I then hired for my entry. It is going to be beautiful and amazing..! ”</blockquote>
    //                           <div className="testimonial-avatar">
    //                             <div className="testimonial-img">
    //                               <img width="150" height="150" className="img-fluid" src="https://via.placeholder.com/150x150/808080?text=150x150+02.jpg" alt="testimonial-img" />                                                            </div>
    //                             <div className="testimonial-caption">
    //                               <h3>Joel Elliott</h3>
    //                               <label>Co-manager associated</label>
    //                             </div>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="col-lg-6">
    //                     <div className="testimonials style1">
    //                       <div className="testimonials-inner-item">
    //                         <div className="testimonial-content">
    //                           <div className="ttm-ratting-star">
    //                             <i className="fa fa-star"></i>
    //                             <i className="fa fa-star"></i>
    //                             <i className="fa fa-star"></i>
    //                             <i className="fa fa-star"></i>
    //                             <i className="fa fa-star-o"></i>
    //                           </div>
    //                           <blockquote>“ They helped me design our new living space & did a fantastic job! Such pleasure to work with every step of the way helped us find a design.”</blockquote>
    //                           <div className="testimonial-avatar">
    //                             <div className="testimonial-img">
    //                               <img width="150" height="150" className="img-fluid" src="https://via.placeholder.com/150x150/808080?text=150x150+03.jpg" alt="testimonial-img" />
    //                             </div>
    //                             <div className="testimonial-caption">
    //                               <h3>Peter Daniels</h3>
    //                               <label>Ceo /Manger</label>
    //                             </div>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div> */}
    //               </div>
    //             </article>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </Layout>
  )
}

export default Layouts