import ContactUsHeader from "../../components/Contact-Us/Header/Header";
import ContactUsMain from "../../components/Contact-Us/Main/Main";
import Layout from "../../components/Layout/Layout";
import { META_DESCRIPTION, META_TITLE } from "../../constants/seo.constants";

const ContactUs = () => {
    return (
        <Layout title={META_TITLE.CONTACT_US} description={META_DESCRIPTION.CONTACT_US}>
            <ContactUsHeader />
            <ContactUsMain />
        </Layout>
    )
}

export default ContactUs;