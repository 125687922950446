import main from '../../assets/images/inte1.jpg';
import discover from '../../assets/images/services/interior/discover.jpg';
import concept from '../../assets/images/services/interior/concept.jpg';
import design from '../../assets/images/services/interior/design.jpg';
import execution from '../../assets/images/services/interior/execution.jpg';
import { META_DESCRIPTION, META_TITLE } from '../../constants/seo.constants';
import Layout2 from '../ServicesLayout/Layout2';

const info = {
  metaTitle: META_TITLE.INTER_DESIGN_SERVICE,
  metaDescription: META_DESCRIPTION.INTER_DESIGN_SERVICE,
  title: "Resdential Projects",
  mainHeading: "Unlock the Potential of Your Space with Livera's Interior Design Services",
  image1: main,
  mainParagraphs: ["At Livera, we understand that interior design is more than just decorating rooms; it's about crafting environments that tell a story and enhance the way you live and work. With 15 years of dedicated experience, we've perfected the art of interior design, offering a range of services that cater to your unique needs and aspirations", "Our approach is deeply rooted in collaboration and creativity. We treat every project as a canvas, and your vision as the palette. By understanding your lifestyle, aspirations, and unique requirements, we curate interior designs that transcend the ordinary, providing you with a space that's both beautiful and practical."],
  points: [
    {
      image: discover,
      title: "Discovery and Consultation",
      description: "Every project begins with a conversation. We start by getting to know you, your preferences, lifestyle, and the specific needs of the space you want to transform. During this initial consultation, we encourage you to share your ideas, dreams, and inspirations. Our goal is to understand your vision and objectives thoroughly."
    },
    {
      image: concept,
      title: "Concept Development",
      description: "With your vision in mind, our team of experienced designers goes to work. We create a comprehensive design concept that aligns with your desires, offering multiple options and ideas for your consideration. This stage is a collaborative process, and we welcome your feedback and input every step of the way."
    },
    {
      image: design,
      title: "Design and Planning",
      description: "Once we've solidified the design concept, we move on to the detailed planning phase. This includes space planning, furniture selection, color schemes, material choices, and lighting design. We pay close attention to every detail to ensure that the design not only looks stunning but also functions seamlessly in your space."
    },
    {
      image: execution,
      title: "Execution and Project Management",
      description: "With the plan in place, we put our vision into action. Our team of skilled professionals, including contractors, artisans, and craftsmen, collaborates closely to bring the design to life. Throughout the project, we provide diligent project management to ensure that everything proceeds according to plan and on schedule."
    },
  ],
  name: "Resdential",
}

const InterDesign = () => {
  return (
    <Layout2 info={info} />
    // <Layout title={META_TITLE.INTER_DESIGN_SERVICE} description={META_DESCRIPTION.INTER_DESIGN_SERVICE}>
    //   <div className="ttm-page-title-row">
    //     <div className="ttm-page-title-row-inner">
    //       <div className="container">
    //         <div className="row align-items-center">
    //           <div className="col-lg-12">
    //             <div className="page-title-heading">
    //               <h2 className="title">Inter Design</h2>
    //             </div>
    //             <div className="breadcrumb-wrapper">
    //               <div className="container">
    //                 <div className="breadcrumb-wrapper-inner">
    //                   <span>
    //                     <Link title="Go to Home." to="/" className="home"><i className="themifyicon ti-home"></i>&nbsp;&nbsp;Home</Link>
    //                   </span>
    //                   <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
    //                   <span>Inter Design</span>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>



    //   <div className="site-main">


    //     <div className="ttm-row sidebar ttm-sidebar-left ttm-bgcolor-white clearfix">
    //       <div className="container">
    //         <div className="row">
    //           <div className="col-lg-4 widget-area sidebar-left">
    //             <aside className="widget widget-nav-menu">
    //               <h3 className="widget-title">More Services</h3>
    //               <ul className="widget-menu">
    //                 <li><Link to="/architecture">Architecture</Link></li>
    //                 <li><Link to="/interior">Interior Work</Link></li>
    //                 <li><Link to="/commercial-design">Retail Designs</Link></li>
    //                 <li><Link to="/layouts">2D/3D Layouts</Link></li>
    //                 <li className="active"><Link to="/inter-design">Inter Design</Link></li>
    //                 <li><Link to="/decoration-art">Decoration art</Link></li>
    //               </ul>
    //             </aside>
    //             <Contact />
    //             <aside className="widget widget-archive">
    //               <h3 className="widget-title">Gallery</h3>
    //               <div id="gallery-2" className="gallery-wrapper">
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch4} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch5} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch6} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch7} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch8} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch9} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch10} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch11} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //                 <figure className="gallery-item">
    //                   <div className="gallery-icon landscape">
    //                     <img width="150" height="150" className="img-fluid" src={arch12} alt="gellary_img" />
    //                   </div>
    //                 </figure>
    //               </div>
    //             </aside>
    //           </div>
    //           <div className="col-lg-8 content-area">
    //             <article className="ttm-service-single-content-area">
    //               <div className="ttm-service-featured-wrapper ttm-featured-wrapper">
    //                 <div className="ttm_single_image-wrapper mb-45 res-991-mb-30">
    //                   <img width="1200" height="800" className="img-fluid" src={arch2} alt="project-9" />
    //                 </div>
    //               </div>
    //               <div className="ttm-service-classic-content">
    //                 <h2>Unlock the Potential of Your Space with Livera's Interior Design Services</h2>
    //                 <p>At Livera, we understand that interior design is more than just decorating rooms; it's about crafting environments that tell a story and enhance the way you live and work. With 15 years of dedicated experience, we've perfected the art of interior design, offering a range of services that cater to your unique needs and aspirations.</p>
    //                 <p>Our approach is deeply rooted in collaboration and creativity. We treat every project as a canvas, and your vision as the palette. By understanding your lifestyle, aspirations, and unique requirements, we curate interior designs that transcend the ordinary, providing you with a space that's both beautiful and practical.</p>
    //                 <div className="ttm-horizontal_sep width-100 mt-30 mb-30 res-991-mt-15"></div>
    //                 <div className="row">
    //                   <div className="col-md-7">
    //                     <h2>Discovery and Consultation</h2>
    //                     <p>Every project begins with a conversation. We start by getting to know you, your preferences, lifestyle, and the specific needs of the space you want to transform. During this initial consultation, we encourage you to share your ideas, dreams, and inspirations. Our goal is to understand your vision and objectives thoroughly.</p>
    //                   </div>
    //                   <div className="col-md-5">
    //                     <div className="ttm_single_image-wrapper res-767-pt-30">
    //                       <img width="450" height="500" className="img-fluid" src={arch1} alt="team-img02" />
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="ttm-horizontal_sep width-100 mt-30 mb-30"></div>
    //                 <div className="row">
    //                   <div className="col-md-5">
    //                     <div className="ttm_single_image-wrapper res-767-pb-30">
    //                       <img width="450" height="500" className="img-fluid" src={arch3} alt="team-img01" />
    //                     </div>
    //                   </div>
    //                   <div className="col-md-7">
    //                     <h2>Concept Development</h2>
    //                     <p>With your vision in mind, our team of experienced designers goes to work. We create a comprehensive design concept that aligns with your desires, offering multiple options and ideas for your consideration. This stage is a collaborative process, and we welcome your feedback and input every step of the way.</p>
    //                   </div>
    //                 </div>
    //                 <div className="ttm-horizontal_sep width-100 mt-30 mb-30 res-991-mt-15"></div>
    //                 <div className="row">
    //                   <div className="col-md-7">
    //                     <h2>Design and Planning</h2>
    //                     <p>Once we've solidified the design concept, we move on to the detailed planning phase. This includes space planning, furniture selection, color schemes, material choices, and lighting design. We pay close attention to every detail to ensure that the design not only looks stunning but also functions seamlessly in your space.</p>
    //                   </div>
    //                   <div className="col-md-5">
    //                     <div className="ttm_single_image-wrapper res-767-pt-30">
    //                       <img width="450" height="500" className="img-fluid" src={arch1} alt="team-img02" />
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="ttm-horizontal_sep width-100 mt-30 mb-30"></div>
    //                 <div className="row">
    //                   <div className="col-md-5">
    //                     <div className="ttm_single_image-wrapper res-767-pb-30">
    //                       <img width="450" height="500" className="img-fluid" src={arch3} alt="team-img01" />
    //                     </div>
    //                   </div>
    //                   <div className="col-md-7">
    //                     <h2>Execution and Project Management</h2>
    //                     <p>With the plan in place, we put our vision into action. Our team of skilled professionals, including contractors, artisans, and craftsmen, collaborates closely to bring the design to life. Throughout the project, we provide diligent project management to ensure that everything proceeds according to plan and on schedule.</p>
    //                   </div>
    //                 </div>
    //               </div>
    //             </article>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </Layout>
  )
}

export default InterDesign