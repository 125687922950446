import main from '../../assets/images/services/projectdevelopment/main.jpg';
import consultation from '../../assets/images/services/projectdevelopment/consultation.jpg';
import concept from '../../assets/images/services/projectdevelopment/concept.jpg';
import development from '../../assets/images/services/projectdevelopment/development.jpg';
import budget from '../../assets/images/services/projectdevelopment/budget.jpg';
import planning from '../../assets/images/services/projectdevelopment/planning.jpg';
import execute from '../../assets/images/services/projectdevelopment/execute.jpg';
import quality from '../../assets/images/services/projectdevelopment/quality.jpg';
import reveal from '../../assets/images/services/projectdevelopment/reveal.jpg';
import postproject from '../../assets/images/services/projectdevelopment/postproject.jpg';

import { META_DESCRIPTION, META_TITLE } from '../../constants/seo.constants';
import Layout2 from '../ServicesLayout/Layout2';

const info = {
  metaTitle: META_TITLE.PROJECT_DEVELOPMENT,
  metaDescription: META_DESCRIPTION.PROJECT_DEVELOPMENT,
  title: "Project Development",
  mainHeading: "Metamorphosing Environments with Livera: Unveiling Our Project Development Journey",
  image1: main,
  mainParagraphs: ["At Livera, we pride ourselves on being the catalysts for change, transforming everyday spaces into remarkable environments. Our commitment to excellence is embodied in our comprehensive project development journey. From the initial spark of inspiration to the final, breathtaking reveal, Livera's meticulous approach ensures that your vision becomes a stunning reality", "With Livera as your partner in interior design, you can expect a seamless and transparent process that prioritizes your satisfaction at every step. We understand that each space we work on is unique, and that's why our project development journey is flexible and adaptable to your specific needs. Whether you're seeking a complete home makeover or a stunning office transformation, Livera is here to elevate your living and working environments. Join us on this exciting journey and let us help you metamorphose your space into something truly extraordinary."],
  points: [
    {
      image: consultation,
      title: "Initial Consultation",
      description: "Every project starts with a conversation. We believe that understanding your vision, needs, and preferences is the key to a successful project. During the initial consultation, our team of experienced designers will meet with you to discuss your ideas, budget, and timeline. This helps us create a personalized plan that aligns with your vision."
    },
    {
      image: concept,
      title: "Conceptualization",
      description: "Once we have a clear understanding of your objectives, our creative team springs into action. We begin brainstorming concepts and ideas that blend aesthetics, functionality, and your personal style. Mood boards, color palettes, and design sketches come together to create a comprehensive design concept for your space"
    },
    {
      image: development,
      title: "Design Development",
      description: "With your feedback on the initial concepts, we refine the design and develop detailed plans. This phase involves selecting materials, furnishings, and fixtures that match your vision while ensuring functionality and durability. Our team also considers sustainable and eco-friendly options whenever possible"
    },
    {
      image: budget,
      title: "Budgeting and Cost Estimation",
      description: "Transparency is essential, and we ensure that you're fully aware of the costs involved. Livera provides a detailed cost estimate, breaking down expenses for materials, labor, and any additional services. We work closely with you to adjust the budget as needed, ensuring that your project remains on track financially."
    },
    {
      image: planning,
      title: "Project Planning",
      description: "Before the actual work begins, Livera crafts a comprehensive project plan. This includes a timeline with milestones and deadlines, as well as a project manager dedicated to overseeing the execution. Our goal is to minimize disruptions and ensure a smooth transition from your current space to the envisioned design."
    },
    {
      image: execute,
      title: "Execution and Installation",
      description: "The heart of our project development process is the execution phase. Our skilled craftsmen, carpenters, and installation teams bring the design to life. They meticulously implement every detail, ensuring that the finished product reflects the original concept."
    },
    {
      image: quality,
      title: "Quality Assurance",
      description: "At Livera, quality is non-negotiable. We conduct thorough quality checks at every stage of the project to ensure that everything meets our exacting standards. From the construction work to the final layer of paint, we leave no stone unturned in pursuit of perfection"
    },
    {
      image: reveal,
      title: "Final Reveal",
      description: "The moment you've been waiting for - the big reveal! Livera presents your newly transformed space in all its glory. We believe that the joy of seeing your dream space come to life is a reward in itself."
    },
    {
      image: postproject,
      title: "Post-Project Support",
      description: "Our commitment doesn't end with the completion of the project. Livera offers post-project support, ensuring that you are satisfied with the final result. Any issues or concerns that arise after the project will be promptly addressed to your satisfaction."
    },
  ],
  name: "devlopment",
}

const ProjectDevelopment = () => {
  return (
    <Layout2 info={info} />
  )
}

export default ProjectDevelopment