import english from './english.json';

const currentLanguage = 'english';

export const LANGUAGE = () => {
    switch (currentLanguage) {
        case 'english':
            return english;
        default:
            return null;
    }
}