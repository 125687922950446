import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Slider.css';

const HomePageHeader = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
    };

    return (
        <div>
            <Slider {...settings}>
                <div className="slider-container">
                    <img
                        title="slider-bg001" width="1920" height="843" className="rev-slidebg" data-no-retina
                        src="/images/slides/slide-3.jpg"
                        alt=""
                    />
                    <div className="slide-content left">
                        <h3>Welcome to Livera</h3>
                        <h1>Best Interior Designers </h1>
                        <p>We known for their bespoke design service where an extra level of detail is added to <br /> each of their projects. Creative, receptive.</p>
                    </div>
                </div>
                <div className="slider-container">
                    <img
                        title="slider-bg002" width="1920" height="843" className="rev-slidebg" data-no-retina
                        src="/images/slides/slide-2.jpg"
                        alt=""
                    />
                    <div className="slide-content right">
                        <h3>Livera Interior</h3>
                        <h1>Designing Dreams</h1>
                        <p>We known for their bespoke design service where an extra level of detail is added to <br /> each of their projects. Creative, receptive.</p>
                    </div>
                </div>
            </Slider>
        </div>
    );
}

export default HomePageHeader;