import { Link } from "react-router-dom";
import arch10 from '../../assets/images/arch10.jpg';
import arch11 from '../../assets/images/arch11.jpg';
import arch12 from '../../assets/images/arch12.jpg';
import arch4 from '../../assets/images/arch4.jpg';
import arch5 from '../../assets/images/arch5.jpg';
import arch6 from '../../assets/images/arch6.jpg';
import arch7 from '../../assets/images/arch7.jpg';
import arch8 from '../../assets/images/arch8.jpg';
import arch9 from '../../assets/images/arch9.jpg';
import arch1 from '../../assets/images/inte1.jpg';
import arch2 from '../../assets/images/inte2.jpg';
import arch3 from '../../assets/images/inte3.jpg';
import Layout from "../Layout/Layout";
import Contact from "./contact/Contact";
import { META_DESCRIPTION, META_TITLE } from "../../constants/seo.constants";

const Interior = () => {
  return (
    <Layout title={META_TITLE.INTERIOR_DESIGN_SERVICE} description={META_DESCRIPTION.INTERIOR_DESIGN_SERVICE}>
      <div className="ttm-page-title-row">
        <div className="ttm-page-title-row-inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="page-title-heading">
                  <h2 className="title">Interior Work</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <div className="container">
                    <div className="breadcrumb-wrapper-inner">
                      <span>
                        <Link title="Go to Home." to="/" className="home"><i className="themifyicon ti-home"></i>&nbsp;&nbsp;Home</Link>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                      <span>Interior Work</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-main">

        <div className="ttm-row sidebar ttm-sidebar-left ttm-bgcolor-white clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 widget-area sidebar-left">
                <aside className="widget widget-nav-menu">
                  <h3 className="widget-title">More Services</h3>
                  <ul className="widget-menu">
                    <li><Link to="/architecture">Architecture</Link></li>
                    <li className="active"><Link to="/interior">Resdential Projects</Link></li>
                    <li><Link to="/commercial-design">Retail Designs</Link></li>
                    <li><Link to="/layouts">2D/3D Layouts</Link></li>
                    <li><Link to="/inter-design">Inter Design</Link></li>
                    <li><Link to="/decoration-art">Decoration art</Link></li>
                  </ul>
                </aside>
                <Contact />
                <aside className="widget widget-archive">
                  <h3 className="widget-title">Gallery</h3>
                  <div id="gallery-2" className="gallery-wrapper">
                    <figure className="gallery-item">
                      <div className="gallery-icon landscape">
                        <img width="150" height="150" className="img-fluid" src={arch4} alt="gellary_img" />
                      </div>
                    </figure>
                    <figure className="gallery-item">
                      <div className="gallery-icon landscape">
                        <img width="150" height="150" className="img-fluid" src={arch5} alt="gellary_img" />
                      </div>
                    </figure>
                    <figure className="gallery-item">
                      <div className="gallery-icon landscape">
                        <img width="150" height="150" className="img-fluid" src={arch6} alt="gellary_img" />
                      </div>
                    </figure>
                    <figure className="gallery-item">
                      <div className="gallery-icon landscape">
                        <img width="150" height="150" className="img-fluid" src={arch7} alt="gellary_img" />
                      </div>
                    </figure>
                    <figure className="gallery-item">
                      <div className="gallery-icon landscape">
                        <img width="150" height="150" className="img-fluid" src={arch8} alt="gellary_img" />
                      </div>
                    </figure>
                    <figure className="gallery-item">
                      <div className="gallery-icon landscape">
                        <img width="150" height="150" className="img-fluid" src={arch9} alt="gellary_img" />
                      </div>
                    </figure>
                    <figure className="gallery-item">
                      <div className="gallery-icon landscape">
                        <img width="150" height="150" className="img-fluid" src={arch10} alt="gellary_img" />
                      </div>
                    </figure>
                    <figure className="gallery-item">
                      <div className="gallery-icon landscape">
                        <img width="150" height="150" className="img-fluid" src={arch11} alt="gellary_img" />
                      </div>
                    </figure>
                    <figure className="gallery-item">
                      <div className="gallery-icon landscape">
                        <img width="150" height="150" className="img-fluid" src={arch12} alt="gellary_img" />
                      </div>
                    </figure>
                  </div>
                </aside>
              </div>
              <div className="col-lg-8 content-area">
                <article className="ttm-service-single-content-area">
                  <div className="ttm-service-featured-wrapper ttm-featured-wrapper">
                    <div className="ttm_single_image-wrapper mb-45 res-991-mb-30">
                      <img width="1200" height="800" className="img-fluid" src={arch1} alt="project-6" />
                    </div>
                  </div>
                  <div className="ttm-service-classic-content">
                    <h2>Elevate Your Space: Livera's Comprehensive Interior Design Services</h2>
                    <p>At Livera, we understand that every individual and every space is unique. That's why we offer a wide array of services tailored to meet your specific requirements. Our goal is not just to design spaces but to create experiences.</p>
                    <p>Our core service revolves around crafting interiors that are aesthetically pleasing, functional, and personalized to your tastes. Whether it's your home, office, or commercial space, Livera's interior design experts are equipped to transform any environment into a work of art</p>
                    <div className="ttm-horizontal_sep width-100 mt-30 mb-30 res-991-mt-15"></div>
                    <div className="row">
                      <div className="col-md-7">
                        <h2>Fully Trained Employees</h2>
                        <p>In the world of interior design and architecture, success hinges on more than just creativity and innovation. It's about the people who bring those ideas to life. At Livera, we take immense pride in our secret sauce for success: a team of fully trained employees. In this blog, we shine a spotlight on the backbone of our organization—the skilled and dedicated individuals who turn dreams into reality.</p>
                        {/* <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-squar ttm-btn-style-border ttm-icon-btn-right ttm-btn-color-darkgrey mt-20" href="team-details.html" tabIndex={0}>Read More</a> */}
                      </div>
                      <div className="col-md-5">
                        <div className="ttm_single_image-wrapper res-767-pt-30">
                          <img width="450" height="500" className="img-fluid" src={arch2} alt="team-img02" />
                        </div>
                      </div>
                    </div>
                    <div className="ttm-horizontal_sep width-100 mt-30 mb-30"></div>
                    <div className="row">
                      <div className="col-md-5">
                        <div className="ttm_single_image-wrapper res-767-pb-30">
                          <img width="450" height="500" className="img-fluid" src={arch3} alt="team-img01" />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <h2>Quality Maintain Every Time</h2>
                        <p>Livera is committed to eco-conscious design. We integrate sustainable materials, energy-efficient solutions, and environmentally friendly practices into our projects, reducing your ecological footprint. We ensure your project stays on schedule and within budget, while maintaining the highest quality standards.</p>
                        {/* <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-squar ttm-btn-style-border ttm-icon-btn-right ttm-btn-color-darkgrey mt-20" href="team-details.html" tabIndex={0}>Read More</a> */}
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Interior