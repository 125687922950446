import { PROJECTS } from '../../../constants/projects.constant';
import { IProject } from '../../../models/project.model';
import ProjectCard from '../Project-Card/Project-Card';

interface ProjectDetailsMainProps {
    project: IProject;
}

const ProjectDetailsMain = ({ project }: ProjectDetailsMainProps) => {
    const {slug, project: _project, clients, location, year, images, coverImageUrl} = project;
    return (
        <div className="site-main">
            <section className="ttm-row project-single-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ttm-pf-single-content-wrapper-innerbox ttm-pf-view-top-image">
                                <div className="ttm-pf-single-content-wrapper">
                                    <div className="ttm_single_image-wrapper">
                                        <img width="1200" height="800" className="img-fluid"
                                            src={coverImageUrl} alt="cover-img"
                                            style={{
                                                height: '600px',
                                                width: '1200px',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    </div>
                                    <div className="ttm-pf-single-detail-box ttm-bgcolor-darkgrey ttm-textcolor-white">
                                        <h2 className="ttm-pf-detailbox-title">Project Details:</h2>
                                        <ul className="ttm-pf-detailbox-list">
                                            <li>
                                                <div className="ttm-pf-data-title">Project</div>
                                                <div className="ttm-pf-data-details">{_project}</div>
                                            </li>
                                            <li>
                                                <div className="ttm-pf-data-title">Clients</div>
                                                <div className="ttm-pf-data-details">{clients}</div>
                                            </li>
                                            <li>
                                                <div className="ttm-pf-data-title">Location</div>
                                                <div className="ttm-pf-data-details">{location}</div>
                                            </li>
                                            <li>
                                                <div className="ttm-pf-data-title">Project Year</div>
                                                <div className="ttm-pf-data-details">{year}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="ttm-pf-single-content-area">
                                    <div className="row mt-25 mb-30">
                                        {
                                            images.map((image, index) => (
                                                <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                                                    <div className="ttm_single_image-wrapper mt-15 mb-15 res-991-mt-20">
                                                        <img
                                                            width="580"
                                                            height="610"
                                                            className="img-fluid"
                                                            src={image}
                                                            alt={"image_" + index}
                                                            style={{
                                                                height: '510px',
                                                                width: '480px',
                                                                objectFit: 'cover'
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="ttm-horizontal_sep width-100 mt-25 mb-25 res-991-mt-15"></div>
                                </div>
                                {
                                    PROJECTS.filter(({ slug: _slug }) => _slug !== slug).length ?
                                        <div className="ttm-pf-single-related-wrapper mb_15">
                                            <h2>Other Projects</h2>
                                            <div className="row">
                                                {
                                                    PROJECTS.filter(({ slug: _slug }) => _slug !== slug).map(({ slug, title, listViewDescription, listViewImageUrl }) => (
                                                        <ProjectCard
                                                            key={slug}
                                                            slug={slug}
                                                            title={title}
                                                            description={listViewDescription}
                                                            imageUrl={listViewImageUrl}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ProjectDetailsMain