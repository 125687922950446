import HomePageHeader from "../../components/Home/Header/Header";
import Layout from "../../components/Layout/Layout";
import HomePageMain from "../../components/Home/Main/Main";

const Home = () => {
    return (
        <Layout isShowTopNavbar>
            <HomePageHeader />
            <HomePageMain />
        </Layout>
    );
}

export default Home