import { useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import ProjectDetailsHeader from "../../components/Project/ProjectDetailsHeader/ProjectDetailsHeader";
import ProjectDetailsMain from "../../components/Project/ProjectDetailsMain/ProjectDetailsMain";
import { PROJECTS } from "../../constants/projects.constant";
import CustomError from "../../components/Error/Error";
import { IProject } from "../../models/project.model";

const ProjectDetails = () => {
    const { slug } = useParams();
    console.log({ slug })
    const project: IProject | undefined = PROJECTS.find(project => project.slug === slug);

    return (
        <Layout title={project?.pageTitle} description={project?.pageDescription}>
            {
                project ?
                    <>
                        <ProjectDetailsHeader title={project.title} />
                        <ProjectDetailsMain project={project} />
                    </> : <CustomError />
            }
        </Layout>
    );
}

export default ProjectDetails