import { Link } from "react-router-dom";
import { BUSINESS_DETAILS } from "../../constants/social.constants";
import SocialMediaLinks from "../Social-Media-Links/Social-Media-Links";
import { LANGUAGE } from "../../localization";
import { FOOTER_QUICK_LINKS } from "../../constants/links.constants";

const Footer = () => {
    const texts = LANGUAGE();

    return (
        <>
            <footer className="footer ttm-bg ttm-bgcolor-darkgrey widget-footer clearfix">
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="container">

                    <div className="second-footer">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                                <div className="widget widget_text clearfix">
                                    <div className="footer-logo">
                                        <Link to="/">
                                            <img width="205" height="65" id="footer-logo-img" className="img-fluid" src="/images/footer-logo.svg" alt="img" />
                                        </Link>
                                    </div>
                                    <div className="textwidget widget-text">
                                        <p className="pr-4">{texts?.ABOUT_TEXT_FOOTER}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                                <div className="widget widget_nav_menu clearfix">
                                    <h3 className="widget-title">Quick Links</h3>
                                    <ul id="menu-footer-service-link" className="menu">
                                        {
                                            FOOTER_QUICK_LINKS.map(link =>
                                                <li key={link.to}><Link to={link.to}>{link.title}</Link></li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-4 widget-area">
                                <div className="widget widget-timing clearfix">
                                    <h3 className="widget-title">Contact</h3>
                                    <div className="featured-icon-box icon-align-before-content icon-ver_align-top style1">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                                <i className="flaticon flaticon-call-1"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Phone Number</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>{BUSINESS_DETAILS.phoneNumber}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="featured-icon-box icon-align-before-content icon-ver_align-top style1">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                                <i className="flaticon flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Email Address</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>{BUSINESS_DETAILS.mail}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="featured-icon-box icon-align-before-content icon-ver_align-top style1">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                                <i className="flaticon flaticon-navigation"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Location</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>{BUSINESS_DETAILS.address.location}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-footer-text">
                        <div className="row copyright">
                            <div className="col-lg-7 col-md-8">
                                <p className="mb-0 res-991-pb-15">Copyright ©2023 <Link to="/">{BUSINESS_DETAILS.businessFullName}</Link>. All rights reserved.</p>
                            </div>
                            <div className="col-lg-5 col-md-4">
                                <div className="social-icons d-flex align-items-center justify-content-end">
                                    <SocialMediaLinks />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <a id="totop" href="#top">
                <i className="fa fa-angle-up"></i>
            </a>
        </>
    );
}

export default Footer