import main from '../../assets/images/services/theater/main.jpg';
import dream from '../../assets/images/services/theater/dream.jpg';
import qulity from '../../assets/images/services/theater/quality.jpg';
import { META_DESCRIPTION, META_TITLE } from '../../constants/seo.constants';
import Layout1 from '../ServicesLayout/Layout1';

const info = {
  metaTitle: META_TITLE.HOME_THEATER,
  metaDescription: META_DESCRIPTION.HOME_THEATER,
  title: "Home Theater",
  mainHeading: "Elevate Your Entertainment Experience with Livera's Home Theater Designs",
  image1: main,
  mainParagraphs: ["At Livera, we recognize that your home is more than just a dwelling; it's your sanctuary. And when it comes to your entertainment space, we believe it should be nothing short of extraordinary. With a legacy of 15 years in the world of interior design, we possess the artistry and know-how to craft opulent home theaters that will transport you to a realm of cinematic enchantment.", "Discover the Livera difference in home theater design, where innovation and luxury come together to create a space that redefines entertainment. Ready to turn your entertainment dreams into reality"],
  points: [
    {
      className: "pt-10",
      image: dream,
      title: "Creating Your Dream Home Theater",
      description: "Transform your vision into reality with Livera's home theater design expertise",
      bulletPoints: [
        "Consultation and Vision",
        "Space Evaluation",
        "Design Concept",
        "Technology Integration",
        "Customization"
      ]
    },
    {
      className: "pt-25",
      image: qulity,
      title: "Execution and Quality Assurance",
      description: "At Livera, our commitment to excellence doesn't end with the design concept. Our dedicated team meticulously manages project execution, ensuring every element aligns seamlessly with your vision.",
      bulletPoints: [
        "Project Management",
        "Quality Control",
        "Budget Management",
        "Renovations Benefit of Service",
        "Ongoing Support"
      ]
    }
  ],
  name: "home-theater"
}

const HomeTheater = () => {
  return (
    <Layout1 info={info} />
  )
}

export default HomeTheater