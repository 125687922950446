import { Link } from "react-router-dom";

interface ProjectCardProps {
    slug: string;
    title: string;
    description: string;
    imageUrl: string;
}

const ProjectCard = ({ slug, title, description, imageUrl }: ProjectCardProps) => {
    return (
        <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
            <div className="featured-imagebox featured-imagebox-portfolio style3">
                <div className="featured-thumbnail">
                    <img
                        width="610" height="750" className="img-fluid" src={imageUrl} alt="img"
                        style={{
                            height: '510px',
                            width: '480px',
                            objectFit: 'cover'
                        }}
                    />
                </div>
                                
                <div className="featured-content-inner">
                    <div className="featured-content">
                        <div className="featured-title">
                            <h3><Link to={`/project/${slug}`}>{title}</Link></h3>
                        </div>
                        <div className="featured-desc">
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className="ttm-footer">
                        <Link
                            className="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-dark"
                            to={`/project/${slug}`}
                        >
                            Read More<i className="ti ti-plus"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectCard;