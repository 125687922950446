import { Link } from 'react-router-dom';
import Layout from '../Layout/Layout';
import SideBar from './SideBar';

const Layout2 = (props: any) => {
  return (
    <Layout title={props.info.metaTitle} description={props.info.metaDescription}>
      <div className="ttm-page-title-row">
        <div className="ttm-page-title-row-inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="page-title-heading">
                  <h2 className="title">{props.info.title}</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <div className="container">
                    <div className="breadcrumb-wrapper-inner">
                      <span>
                        <Link title="Go to Home." to="/" className="home"><i className="themifyicon ti-home"></i>&nbsp;&nbsp;Home</Link>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                      <span>{props.info.title}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <div className="ttm-row sidebar ttm-sidebar-left ttm-bgcolor-white clearfix">
          <div className="container">
            <div className="row">
              <SideBar name={props.info.name} />
              <div className="col-lg-8 content-area">
                <article className="ttm-service-single-content-area">
                  <div className="ttm-service-featured-wrapper ttm-featured-wrapper">
                    <div className="ttm_single_image-wrapper mb-45 res-991-mb-30">
                      <img width="1200" height="800" className="img-fluid" src={props.info.image1} alt="project-9"
                        style={{
                          height: '450px',
                          width: '1200px',
                          objectFit: 'cover'
                        }}
                      />
                    </div>
                  </div>
                  <div className="ttm-service-classic-content">
                    <h2>{props.info.mainHeading}</h2>
                    {props.info.mainParagraphs.map((e: string) => {
                      return <p key={e}>{e}</p>
                    })}
                    {
                      props.info.points.map((e: any, i: number) => {
                        return (
                          <div key={Math.random()}>
                            {
                              i % 2 === 0 ? <>
                                <div className="ttm-horizontal_sep width-100 mt-30 mb-30 res-991-mt-15"></div>
                                <div className="row">
                                  <div className="col-md-7">
                                    <h2>{e.title}</h2>
                                    <p>{e.description}</p>
                                  </div>
                                  <div className="col-md-5">
                                    <div className="ttm_single_image-wrapper res-767-pt-30">
                                      <img width="450" height="500" className="img-fluid" src={e.image} alt="team-img02" />
                                    </div>
                                  </div>
                                </div>
                              </> :
                                <>
                                  <div className="ttm-horizontal_sep width-100 mt-30 mb-30"></div>
                                  <div className="row">
                                    <div className="col-md-5">
                                      <div className="ttm_single_image-wrapper res-767-pb-30">
                                        <img width="450" height="500" className="img-fluid" src={e.image} alt="team-img01" />
                                      </div>
                                    </div>
                                    <div className="col-md-7">
                                      <h2>{e.title}</h2>
                                      <p>{e.description}</p>
                                    </div>
                                  </div>
                                </>
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Layout2