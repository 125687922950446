import { IProject } from "../models/project.model";

export const PROJECTS: IProject[] = [
    {
        slug: 'poulomi-aristos-triplex-villa',
        title: 'Poulomi Aristo',
        project: 'Triplex Villa',
        clients: 'Sudhakar',
        location: 'Narsingi, Hyderabad, Telangana',
        year: '-',
        listViewDescription: "Poulomi Aristo, Livera's exquisite creation, is a triplex villa designed for the discerning taste of our client, Sudhakar. This unique living space seamlessly fuses architectural elegance with personalized functionality, reflecting our dedication to crafting exceptional interior designs.",
        listViewImageUrl: "/images/projects-images/poulomia-aristos/kitchen-2.jpg",
        coverImageUrl: '/images/projects-images/poulomia-aristos/sink.jpg',
        images: [
            "/images/projects-images/poulomia-aristos/fridge.jpg",
            "/images/projects-images/poulomia-aristos/home-theater.jpg",
            "/images/projects-images/poulomia-aristos/kitchen.jpg",
            "/images/projects-images/poulomia-aristos/bedroom.jpg",
            "/images/projects-images/poulomia-aristos/stair-case.jpg",
        ],
        pageTitle: 'Livera: Poulomi Aristo | Triplex Villa',
        pageDescription: 'Poulomi Aristo - Triplex Villa'
    },
    {
        slug: 'Aparna-Sarovar-Zenith-3BHK-Residential', // ! title + client + location
        title: 'Aparna Sarovar Zenith',
        project: '3BHK Residential',
        clients: 'Sivarama krishna Pushpala',
        location: 'Gachibowli, Hyderabad, Telangana',
        year: '-',
        listViewDescription: "Aparna Sarovar Zenith, a Livera creation, is a 3BHK residential marvel tailored to the refined taste of Sivarama Krishna Pushpala, showcasing our 15 years of interior design expertise. Elegance and functionality converge in this serene living space.",
        listViewImageUrl: "/images/projects-images/aparna-sarovar/wash-sink-mirror-interior.jpg",
        coverImageUrl: '/images/projects-images/aparna-sarovar/enterance.jpg',
        images: [
            "/images/projects-images/aparna-sarovar/wash-sink-mirror-interior.jpg",
            "/images/projects-images/aparna-sarovar/mid-space.jpg",
            "/images/projects-images/aparna-sarovar/mid-space-2.jpg",
            "/images/projects-images/aparna-sarovar/kitchen-ceiling.jpg",
            "/images/projects-images/aparna-sarovar/entrance-living-room.jpg",
            "/images/projects-images/aparna-sarovar/children-bedroom.jpg",
        ],
        pageTitle: 'Livera: Aparna Sarovar Zenith | 3BHK Residential',
        pageDescription: 'Aparna Sarovar Zenith - 3BHK Residential'
    },
    {
        slug: 'Jayabheri-Summit-3BHK-Residential-Anupama-Raju',
        title: 'Jayabheri Summit',
        project: '3BHK Residential',
        clients: 'Anupama Raju',
        location: 'Narsingi, Hyderabad, Telangana',
        year: '-',
        listViewDescription: "Jayabheri Summit, a 3BHK residential project by Livera, showcases our dedication to interior design. Tailored to our client Anupama Raju's preferences, this space harmoniously merges style and practicality for an exceptional living environment.",
        listViewImageUrl: "/images/projects-images/jayabheri-summit-1/kitchen-2.jpg",
        coverImageUrl: '/images/projects-images/jayabheri-summit-1/kitchen-2.jpg',
        images: [
        "/images/projects-images/jayabheri-summit-1/living-room.jpg",
        "/images/projects-images/jayabheri-summit-1/bedroom.jpg",
        "/images/projects-images/jayabheri-summit-1/bedroom-2.jpg",
        ],
        pageTitle: 'Livera: Jayabheri Summit | 3BHK Residential',
        pageDescription: 'Jayabheri Summit - 3BHK Residential'
    },
    {
        slug: 'Jayabheri-Summit-3BHK-Residential-Chandra-Reddy',
        title: 'Jayabheri Summit',
        project: '3BHK Residential',
        clients: 'Surya Chandra Reddy',
        location: 'Narsingi, Hyderabad, Telangana',
        year: '-',
        listViewDescription: 'Discover Jayabheri Summit, a Livera-designed 3BHK residential project tailored to the exquisite tastes of our client, Surya Chandra Reddy. This space beautifully blends aesthetics and functionality, creating a luxurious living environment that reflects our commitment to top-notch interior design.',
        listViewImageUrl: "/images/projects-images/jayabheri-summit-2/3d-render-kitchen-2.jpg",
        coverImageUrl: '/images/projects-images/jayabheri-summit-2/3d-render-kitchen-2.jpg',
        images: [
            "/images/projects-images/jayabheri-summit-2/3d-render-kitchen-1.jpg",
            "/images/projects-images/jayabheri-summit-2/kitchen.jpg",
            "/images/projects-images/jayabheri-summit-2/kitchen-2.jpg",
        ],
        pageTitle: 'Livera: Jayabheri Summit | 3BHK Residential',
        pageDescription: 'Jayabheri Summit - 3BHK Residential'
    },
    {
        slug: 'Lansum-Etania-4BHK-Residential',
        title: 'Lansum Etania',
        project: '4BHK Residential',
        clients: 'Lakshmi Prasanna',
        location: 'Rajendra Nagar Mandal, Hyderabad, Telangana',
        year: '-',
        listViewDescription: "Lansum Etania, a Livera masterpiece, is a 4BHK residential project that's been meticulously designed to meet the unique preferences of our client, Lakshmi Prasanna. This space elegantly combines style and functionality, offering a luxurious living experience that exemplifies our commitment to exceptional interior design.",
        listViewImageUrl: "/images/projects-images/lansum-etania/mirror.jpeg",
        coverImageUrl: "/images/projects-images/lansum-etania/3d-kitchen.jpeg",
        images: [
            "/images/projects-images/lansum-etania/kitchen.jpeg",
            "/images/projects-images/lansum-etania/kitchen-2.jpeg",
            "/images/projects-images/lansum-etania/mirror-2.jpeg",
            "/images/projects-images/lansum-etania/sofa.jpeg",
            "/images/projects-images/lansum-etania/tv.jpeg",
            "/images/projects-images/lansum-etania/dining-table.jpeg",
            "/images/projects-images/lansum-etania/dining-tv.jpeg",
        ],
        pageTitle: 'Livera: Lansum Etania | 4BHK Residential',
        pageDescription: 'Lansum Etania - 4BHK Residential'
    },
    {
        slug: 'Lanco-Hills-3BHK-Residential',
        title: 'Lanco Hills',
        project: '3BHK Residential',
        clients: 'Vishnu Vardhan',
        location: 'Hyderabad, Manikonda Jagir, Telangana',
        year: '-',
        listViewDescription: 'Lanco Hills, a Livera creation, is a 3BHK residential project designed to perfection for our valued client, Vishnu Vardhan. This space seamlessly marries style and practicality, resulting in an inviting living environment that embodies our unwavering dedication to superior interior design.',
        listViewImageUrl: "/images/projects-images/lanco-hills/kitchen-4.jpg",
        coverImageUrl: '/images/projects-images/lanco-hills/3d-render-kitchen-1.jpg',
        images: [
            "/images/projects-images/lanco-hills/kitchen-1.jpg",
            "/images/projects-images/lanco-hills/kitchen-2.jpg",
            "/images/projects-images/lanco-hills/kitchen-3.jpg",
            "/images/projects-images/lanco-hills/kitchen-4.jpg",
        ],
        pageTitle: 'Livera: Lanco Hills | 3BHK Residential',
        pageDescription: 'Lanco Hills - 3BHK Residential'
    },
    {
        slug: 'Honer-Homes-3BHK-Residential',
        title: 'Honer Homes',
        project: '3BHK Residential',
        clients: 'Kalyana chakravarthy Gonepudi',
        location: 'Gachibowli, Hyderabad, Telangana',
        year: '-',
        listViewDescription: "Honer Homes, a Livera creation, is a 3BHK residential project meticulously designed for our esteemed client, Kalyana Chakravarthy Gonepudi. This space effortlessly combines style and functionality, resulting in a welcoming living environment that exemplifies our unwavering commitment to exceptional interior design.",
        listViewImageUrl: "/images/projects-images/honor-homes/entrance.jpg",
        coverImageUrl: '/images/projects-images/honor-homes/ceiling.jpg',
        images: [
            "/images/projects-images/honor-homes/mirror.jpg",
            "/images/projects-images/honor-homes/kitchen.jpg",
            "/images/projects-images/honor-homes/living-room.jpg",
            "/images/projects-images/honor-homes/tv.jpg",
        ],
        pageTitle: 'Livera: Jayabheri Summit | 3BHK Residential',
        pageDescription: 'Jayabheri Summit - 3BHK Residential'
    },
]