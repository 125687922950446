import homeclient from '../../assets/images/services/homeautomation/homeclient.jpg';
import optimzation from '../../assets/images/services/homeautomation/optimzation.jpg';
import installation from '../../assets/images/services/homeautomation/installation.jpg';
import customized from '../../assets/images/services/homeautomation/homeauto.jpg';
import teaching from '../../assets/images/services/homeautomation/teaching.jpg';
import main from '../../assets/images/services/homeautomation/main.jpg';
import { META_DESCRIPTION, META_TITLE } from '../../constants/seo.constants';
import Layout2 from '../ServicesLayout/Layout2';

const info = {
  metaTitle: META_TITLE.HOME_AUTOMATION,
  metaDescription: META_DESCRIPTION.HOME_AUTOMATION,
  title: "Home Automation",
  mainHeading: "Transforming Homes with Cutting-Edge Home Automation",
  image1: main,
  mainParagraphs: ["At Livera, we are dedicated to the art of interior design and development, and we believe that the future of homes lies in seamlessly integrating technology into your living spaces. Our Home Automation Services represent a harmonious blend of creativity and innovation, promising to elevate your home life to new heights. With years of experience in crafting exceptional interiors, we have expanded our horizons to bring you cutting-edge home automation solutions.", "Our mission is to transform your living spaces into intelligent, efficient, and luxurious environments that cater to your every need. Join us on a journey to experience a lifestyle that is not only connected but also exquisitely designed, where every aspect of your home reflects modern elegance."],
  points: [
    {
      image: homeclient,
      title: "Initial Consultation",
      description: "Our journey begins with an in-depth conversation where we understand your unique requirements, preferences, and aspirations for your automated home. We'll discuss your desires for lighting control, security, entertainment, climate, and more, ensuring that our automation system aligns perfectly with your vision."
    },
    {
      image: customized,
      title: "Customized Design",
      description: "Once the design is finalized, our expert technicians will execute the installation with precision and care. From wiring to programming, we ensure that every component functions flawlessly and is integrated into a unified system, leaving you with a home that's ready to operate at your fingertips."
    },
    {
      image: installation,
      title: "Professional Installation",
      description: "With your input in mind, Livera's skilled designers will create a personalized home automation plan tailored to your specific needs. We meticulously choose and integrate state-of-the-art technologies, ensuring that your automation system seamlessly complements your interior design."
    },
    {
      image: teaching,
      title: "User Training and Support",
      description: "Knowledge is power, and at Livera, we empower our clients. We provide comprehensive user training to ensure you are confident in using your new home automation system. Plus, our dedicated support team is available to assist with any questions or issues, ensuring uninterrupted enjoyment of your automated living space"
    },
    {
      image: optimzation,
      title: "Ongoing Optimization",
      description: "Technology evolves, and so do your needs. Livera offers continuous support and maintenance services to keep your home automation system up-to-date and optimized for peak performance. We stay ahead of the curve, introducing new features and enhancements to keep your smart home cutting-edge."
    },
  ],
  name: "home-automation",
}

const HomeAutomation = () => {
  return (
    <Layout2 info={info} />
  )
}

export default HomeAutomation